<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row class="hideOnPagePrint">
        <v-col class="d-flex flex-row align-center">
          <v-btn @click="$router.go(-1)" text><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h1>Quarterly Sales</h1>
          <v-btn v-if="data && data.totaled && data.totaled.length>0" @click="printData" class="ml-2" fab color="info" x-small><v-icon>mdi-printer</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row class="hideOnPagePrint">
        <v-col>
          <h3>Filters</h3>
          <span class="d-flex flex-row">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.endDate"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.endDate"
              />
            </v-menu>
            <v-btn :loading="loader" class="mt-2 ml-2" fab small :disabled="!filters.endDate" color="info" @click="fetchReport"><v-icon>mdi-check</v-icon></v-btn>
            <v-spacer/>
            <v-spacer/>
          </span>

          <span class="d-flex flex-row">
            <v-autocomplete
                class="mx-1"
                :disabled="filters.excludeUser.length>0"
                v-model="filters.users"
                :items="getUserCache"
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')"
                item-value="id"
                outlined
                hide-details
                multiple
                label="Select Users"
                clearable
            ></v-autocomplete>
            <v-btn :disabled="!filters.excludeUser && !filters.users" class="mt-2" color="warning" @click="swapUserFields"><v-icon>mdi-swap-horizontal</v-icon></v-btn>
            <v-autocomplete
                class="mx-1"
                :disabled="filters.users.length>0"
                v-model="filters.excludeUser"
                :items="getUserCache"
                multiple
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')"
                item-value="id"
                outlined
                hide-details
                label="Exclude Users"
                clearable
            ></v-autocomplete>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length===0">
        <v-col>
          <span class="red--text">No records found with the provided filters.</span>
        </v-col>
      </v-row>
      <v-row v-if="data">
        <v-col>
          <span v-if="dates.length>0">Showing Quarterly Sales between {{`${utils.parseDate(dates[0], 2)}`}} - {{`${utils.parseDate(filters.endDate, 2)}`}}</span>
          <span v-if="filters.users.length>0">
            <br>
            <span>for Users: {{filters.users.map(x => lookupUsername(x)).join(", ")}}</span>
          </span>
          <span v-if="filters.excludeUser.length>0">
            <br>
            <span>excluding Users: {{filters.excludeUser.map(x => lookupUsername(x)).join(", ")}}</span>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="data && data.totaled && data.totaled.length>0">
        <v-col>
          <table style="width: 100%;">
              <thead>
              <tr>
                <th class="text-left">Q1</th>
                <th class="text-left">Q2</th>
                <th class="text-left">Q3</th>
                <th class="text-left">Q4</th>
                <th class="text-right">Grand Total</th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-for="(d, i) of data.totaled" :key="i">{{utils.formatCurrency(d)}}</td>
                  <td class="text-right">{{utils.formatCurrency(data.totaled.reduce((a, b) => a+b), 0)}}</td>
                </tr>
              </tbody>
          </table>
        </v-col>
      </v-row>
      <v-row v-if="data && data.filtered && data.totaled.length>0">
        <v-col>
          <span v-for="(q, i) of data.filtered" :key="i" style="font-size: 14px;">
            <h2>Q{{i+1}}</h2>
            <table style="width: 100%;">
              <thead>
              <tr>
                <th class="text-left">Invoice #</th>
                <th class="text-left">Invoice Date</th>
                <th class="text-left">Invoice Total</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(r, j) of q" :key="j">
                <td>{{r.id}}</td>
                <td>{{utils.formatDate(r.sealedAt)}}</td>
                <td>{{r.metadata.grandTotal!==null?utils.formatCurrency(r.metadata.grandTotal):"ERROR"}}</td>
              </tr>
              </tbody>
            </table>
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
  table{
    border-collapse: collapse;
  }
  tr{
    border-bottom: 1px solid #ccc;
  }
</style>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import utils from "../../plugins/helpers"
  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: false,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        filters: {
          endDate: null,
          users: [],
          excludeUser: []
        },

        dates: [],
        data: null,
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", 'lookupUsername', 'getUserCache', 'getGlobalValue', 'paymentTypes']),
    },
    methods: {
      rowClick(row){
        this.$router.push({ path: `/orders/view/${row.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async getAllData(){
        try {
          this.loader = true;

          //let now = new Date();
          let lastDay = new Date();
          let users = [];
          let excludeUser = [];

          if(this.$route.query.endDate){
            lastDay = new Date(this.$route.query.endDate);
          }
          if(this.$route.query.users){
            users = Array.isArray(this.$route.query.users)?(this.$route.query.users.map(x => parseInt(x))):[parseInt(this.$route.query.users)];
          }
          if(this.$route.query.excludeUser){
            if(users.length===0){
              excludeUser = Array.isArray(this.$route.query.excludeUser)?(this.$route.query.excludeUser.map(x => parseInt(x))):[parseInt(this.$route.query.excludeUser)];
            }
          }

          this.filters.endDate = this.utils.parseDate(lastDay);
          this.filters.users = users
          this.filters.excludeUser = excludeUser

          this.$forceUpdate();
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async fetchReport(){
        try {
          this.loader = true;
          this.data = null;
          this.dates = [];

          let res = await axios.post(`${this.getEndpoint}/api/reporting/quarterlysales`, this.filters)
          if(res.data.error) throw res.data.error

          if(!this.$route.query.endDate){
            await this.$router.push({query: {...this.filters}});
          }
          else{
            let changed = false;

            if(Object.keys(this.$route.query).length!==Object.keys(this.filters).length) changed = true;

            if(!changed){
              for(let key of Object.keys(this.$route.query)){
                if(this.filters[key]!=this.$route.query[key] && !Array.isArray(this.filters[key])){
                  changed = true;
                  break;
                }
                if(Array.isArray(this.filters[key])){
                  for(let i=0;i<this.filters[key].length;i++){
                    if(this.filters[key][i]!=this.$route.query[key][i]){
                      changed = true;
                      break;
                    }
                  }
                }
              }
            }
            if(changed) await this.$router.replace({query: {...this.filters}});
          }

          if(res.data.data.length===0) throw "No records found within the provided filters."
          console.log(res.data)
          this.data = res.data.data;
          this.dates = res.data.dates;
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async printData(){
        let els = document.querySelectorAll(".hideOnPagePrint");
        let defaults = [];
        for(let el of els){
          defaults.push(el.style.display);
          el.style.setProperty("display", "none", "important");
        }

        let els2 = document.querySelectorAll(".adjustForPagePrint");
        let defaults2 = [];
        // let defaults3 = [];
        for(let el of els2){
          defaults2.push(el.style.padding);
          // defaults3.push(el.style.paddingRight);
          el.style.setProperty("padding", "0px 50px 0px 0px");
          // el.style.setProperty("paddingRight", "20px");
        }

        window.print();

        for(let i=0;i<els.length;i++){
          els[i].style.setProperty("display", defaults[i], "");
        }

        for(let i=0;i<els2.length;i++){
          els2[i].style.setProperty("padding", defaults2[i], "");
        }
      },
      async swapUserFields(){
        if(this.filters.users.length>0){
          let temp = this.filters.users;
          this.filters.users = [];
          this.filters.excludeUser = temp;
        }
        else if(this.filters.excludeUser.length>0){
          let temp = this.filters.excludeUser;
          this.filters.excludeUser = [];
          this.filters.users = temp;
        }
      }
    }
  }
</script>
